import React, { useEffect, useState } from "react";
import {
  Heading,
  Text,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Box,
  useToast,
  Input,
  SimpleGrid,
  AspectRatio,
  Image,
  ButtonGroup,
  UnorderedList,
  ListItem,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Portal,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";

import { Button } from "../../../components/Button";
import { Appbar } from "../../../components/Appbar";
import {
  addFacts,
  updateFacts,
  AthenaFact,
  Fact,
  getFacts,
  removeFacts,
  updateContext,
} from "../../../api/flow";
import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  SearchIcon,
} from "../../../constants/icons";
import { KnowledgebaseChat } from "./Chat";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { FactInput } from "./FactInput";
import { generateCSVMapping, getRowsFromFacts } from "../../../utils";
import { TextInput } from "@tremor/react";
import { BiUpload } from "react-icons/bi";
import { CSVInput } from "../../../components/Input/Csv";

/**
 * helper function to get facts from the csv
 * @param csvData
 * @returns array of facts
 */
const getFactsFromCSV = (
  csvData: {
    [key: string]: any;
  }[]
): Fact[] => {
  let facts: Fact[] = [];
  let answerMapping = {
    Answer: `(${["answer", "answers"].join("|")})`,
  };
  let headers = generateCSVMapping(csvData, answerMapping);

  csvData.map((eachRow) => {
    const answerKey = headers["Answer"] ?? "";
    const questionList = Object.keys(eachRow).map((key) => {
      if (key.match(answerKey)) return "";
      // UTF-8 Encoding
      return decodeURIComponent(escape(eachRow[key]));
    });
    const answer = `${eachRow?.[answerKey]}` ?? "";

    if (answer.length < 5 || answer.length > 1000)
      throw new Error("Answer length should be between 5 and 1000 characters");

    if (questionList) {
      facts.push({
        questions: questionList.filter((value) => value !== ""),
        answer,
      });
    }
  });

  return facts;
};

export const Knowledge = () => {
  const { id: flowId } = useParams();
  const message = useToast();
  const { getAuthToken } = useAuthToken();

  const [loading, setLoading] = useState(false);
  const [exportKey, setExportKey] = useState(0);
  const [showNewFact, setShowNewFact] = useState(false);
  const [currentEditingIndex, setCurrentEditingIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [context, setContext] = useState<string>("");
  const [isEditingContext, setIsEditingContext] = useState<boolean>(false);
  const [facts, setFacts] = useState<AthenaFact[]>([]);
  const [csvData, setCsvData] = useState<Fact[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const factSearchResult = facts.filter(
    (fact) =>
      [...fact.questions, fact.answer].filter((question) =>
        question.toLowerCase().includes(searchQuery.toLowerCase())
      ).length
  );

  const addNewFact = async ({
    fact,
    showToast = true,
    ...rest
  }: {
    fact: Fact;
    showToast?: boolean;
    onSuccess?: () => void;
  }) => {
    const questions = fact.questions.filter((value) => value !== "");

    if (questions.length === 0 || fact.answer.length === 0) {
      message({
        status: "error",
        description: "question is empty",
      });
      return false;
    }

    setLoading(true);

    const token = localStorage.getItem('token')
    const response = await addFacts({
      token,
      flowId,
      facts: [
        {
          kb_id: "",
          answer: fact.answer,
          questions: questions,
          remote_media_attachment: fact.remote_media_attachment,
          data: {
            followup_buttons: fact.data?.followup_buttons ?? [],
          },
        },
      ],
    }).catch((_) => {
      setLoading(false);
    });
    setLoading(false);

    if (response) {
      setFacts((prevState) => [...response, ...prevState]);
      setShowNewFact(false);
      if (showToast)
        message({
          status: "success",
          description: "new fact added",
        });
      rest.onSuccess?.();
      return true;
    }

    message({
      status: "error",
      description: "failed to add the fact. It's not you, it's us :(",
    });

    return false;
  };

  const updateFact = async ({
    fact,
    showToast = true,
    ...rest
  }: {
    fact: Fact;
    showToast?: boolean;
    onSuccess?: () => void;
  }) => {
    const questions = fact.questions.filter((value) => value !== "");

    if (questions.length === 0 || fact.answer.length === 0) {
      message({
        status: "error",
        description: "question is empty",
      });
      return false;
    }

    setLoading(true);

    const token = localStorage.getItem('token')
    const response = await updateFacts({
      token,
      flowId,
      facts: [
        {
          kb_id: "",
          answer: fact.answer,
          questions: questions,
          remote_media_attachment: fact.remote_media_attachment,
          data: {
            followup_buttons: fact.data?.followup_buttons ?? [],
          },
        },
      ],
    }).catch((_) => {
      setLoading(false);
    });
    setLoading(false);

    if (response) {
      setFacts((prevState) => [...response, ...prevState]);
      setShowNewFact(false);
      if (showToast)
        message({
          status: "success",
          description: "new fact added",
        });
      rest.onSuccess?.();
      return true;
    }

    message({
      status: "error",
      description: "failed to add the fact. It's not you, it's us :(",
    });

    return false;
  };

  const deleteFact = async ({
    fact,
    showToast = true,
    ...rest
  }: {
    fact: AthenaFact;
    showToast?: boolean;
    onSuccess?: () => void;
  }) => {
    setLoading(true);
    const token = localStorage.getItem('token')
    console.log(fact)
    const factID = fact.kb_id
    const response = await removeFacts({
      token,
      flowId,
      facts: fact,
    }).catch((_) => setLoading(false));
    setLoading(false);

    if (response && response.status === "success") {
      setFacts((prevState) =>
        prevState.filter((fact) => fact.kb_id !== factID)
      );
      rest.onSuccess?.();
      if (showToast)
        message({
          status: "success",
          description: "removed fact",
        });
    }
  };

  const updateKBContext = async (showToast: boolean = true) => {
    setLoading(true);
    const token = localStorage.getItem('token')
    const response = await updateContext({
      token,
      flowId,
      context,
    }).catch((_) => setLoading(false));
    setLoading(false);

    if (response && response.success) {
      if (showToast)
        message({
          status: "success",
          description: "updated context",
        });
    }
  };

  const importFacts = async (facts: Fact[]) => {
    if (facts.length === 0) return;

    setLoading(true);

    const token = localStorage.getItem('token')
    const response = await addFacts({
      token,
      flowId,
      facts: facts.map((fact) => ({
        kb_id: "",
        answer: fact.answer,
        questions: fact.questions,
        remote_media_attachment: fact.remote_media_attachment,
        data: {
          followup_buttons: fact.data?.followup_buttons ?? [],
        },
      })),
    }).catch((_) => {
      setLoading(false);
    });
    setLoading(false);

    if (response) {
      setFacts((prevState) => [...response, ...prevState]);
      setShowNewFact(false);
      setCsvData([]);
      onClose();
      message({
        status: "success",
        description: `imported ${facts.length} facts`,
      });
      return true;
    }

    onClose();
    return false;
  };

  useEffect(() => {
    const fetchFacts = async () => {
      setLoading(true);
      const token = localStorage.getItem('token')
      const response = await getFacts({ flowId, token }).catch((_) =>
        setLoading(false)
      );
      setLoading(false);

      if (response) {
        setFacts(response.facts);
        setContext(response.context);
      }
    };

    fetchFacts();
  }, []);

  return (
    <>
      <SimpleGrid gridTemplateColumns="minmax(0,2fr) minmax(0,1fr)">
        <Stack h="100vh" overflowY="scroll">
          <Appbar
            variant="sticky"
            title="Facts"
            px={4}
            borderBottomWidth="thin"
            boxShadow="sm"
            trailing={
              <ButtonGroup>
                <Button
                  variant="secondary"
                  leftIcon={<BiUpload />}
                  onClick={() => onOpen()}
                >
                  Import Facts
                </Button>
                <Button
                  leftIcon={<AddIcon />}
                  onClick={() => setShowNewFact((prevState) => !prevState)}
                >
                  New Fact
                </Button>
              </ButtonGroup>
            }
          />
          {/* edit context */}
          <HStack p={4} align="start" borderBottomWidth="thin">
            <Stack spacing={1} flex={1}>
              <Heading size="md">Get Started</Heading>
              {isEditingContext ? (
                <Input
                  variant="unstyled"
                  value={context}
                  placeholder="Start describing your product..."
                  w="full"
                  onChange={(event) => setContext(event.target.value)}
                />
              ) : (
                <Text>{context}</Text>
              )}
              {!isEditingContext && !context.length && (
                <Text fontSize="md">
                  Describe your product to help us answer better.
                </Text>
              )}
            </Stack>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                if (isEditingContext) updateKBContext();
                setIsEditingContext((edit) => !edit);
              }}
            >
              {isEditingContext ? "Save" : context === "" ? "Try Now" : "Edit"}
            </Button>
          </HStack>
          <HStack px={4} py={2} justify="space-between">
            <TextInput
              className="w-1/2"
              value={searchQuery}
              icon={SearchIcon}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              placeholder="Search for facts"
            />
            {facts.length !== 0 && (
              <CSVLink
                key={`${flowId}_export_${exportKey}`}
                data={loading ? [] : getRowsFromFacts(facts)}
                filename={`${flowId}_facts_export_${exportKey}.csv`}
                target="_blank"
              >
                <Button
                  variant="secondary"
                  leftIcon={<ExportIcon />}
                  onClick={() => setExportKey((prevState) => prevState + 1)}
                >
                  Export
                </Button>
              </CSVLink>
            )}
          </HStack>

          {/* empty state */}
          <Stack
            h="calc(100vh - 200px)"
            justify="center"
            alignSelf="center"
            hidden={showNewFact || facts.length !== 0}
          >
            <Stack
              spacing={4}
              bg="white"
              borderRadius="lg"
              px={4}
              py={6}
              borderWidth="thin"
              maxW="md"
            >
              <Text fontSize="xl">
                Use assesshub’s magic to <b>answer your user’s queries</b>,
                instantly.
              </Text>
              <Text fontSize="lg" color="gray.600">
                Simply give us a few FAQs and answers, and we handles the rest!
              </Text>
              <Button onClick={() => setShowNewFact((prevState) => !prevState)}>
                Try now
              </Button>
            </Stack>
          </Stack>

          {/* add new fact */}
          <Box mt={8} hidden={!showNewFact}>
            <FactInput
              p={4}
              mx={4}
              flowId={flowId}
              spacing={4}
              bg="white"
              borderRadius="md"
              borderWidth="thin"
              boxShadow="md"
              onSave={async (fact) => {
                const result = await addNewFact({ fact });
                return result;
              }}
              onCancel={() => setShowNewFact(false)}
            />
          </Box>

          {/* list of facts */}
          <Accordion
            as={Stack}
            allowToggle
            w="full"
            borderRadius="lg"
            flex={1}
            p={4}
            overflowY="scroll"
          >
            {factSearchResult.map((fact, index) =>
              index === currentEditingIndex ? (
                <FactInput
                  flowId={flowId}
                  p={4}
                  bg="white"
                  spacing={4}
                  borderRadius="md"
                  borderWidth="thin"
                  fact={{
                    questions: fact.questions,
                    answer: fact.answer,
                    remote_media_attachment: fact.remote_media_attachment,
                    data: {
                      followup_buttons: fact.data?.followup_buttons ?? [],
                    },
                  }}
                  onSave={async (updatedFact) => {
                    if (loading) return false;
                    console.log("updatedFact-->", updatedFact);

                    return await updateFact({
                      fact: updatedFact,
                      showToast: false,
                      onSuccess: () => {
                        message({
                          status: "success",
                          description: "updated fact",
                        });
                        setCurrentEditingIndex(-1);
                      },
                    });
                  }}
                  onCancel={() => {
                    setShowNewFact(false);
                    setCurrentEditingIndex(-1);
                  }}
                />
              ) : (
                <AccordionItem
                  key={fact.kb_id}
                  bg="gray.50"
                  borderRadius="md"
                  borderWidth="thin"
                >
                  <AccordionButton px={4} py={2} role="group" borderRadius="md">
                    <HStack w="full" align="start" justify="space-between">
                      <Heading textAlign="left" size="sm">
                        {fact.questions[0]}
                      </Heading>
                      <HStack>
                        <Button
                          leftIcon={<DeleteIcon />}
                          askConfirmation
                          confirmText="Are you sure you want to delete this?"
                          aria-label={"remove"}
                          visibility="hidden"
                          size="xs"
                          _groupHover={{ visibility: "visible" }}
                          variant="secondary"
                          onClick={() => {
                            console.log(fact);
                            deleteFact({ fact: fact });
                          }}
                          isDisabled={loading}
                          confirmAction="delete"
                          confirmButton="Delete"
                        >
                          Delete
                        </Button>
                        <Button
                          leftIcon={<EditIcon />}
                          aria-label={"edit"}
                          visibility="hidden"
                          size="xs"
                          _groupHover={{ visibility: "visible" }}
                          variant="secondary"
                          onClick={() => setCurrentEditingIndex(index)}
                          isDisabled={loading}
                        >
                          Edit
                        </Button>
                        <AccordionIcon />
                      </HStack>
                    </HStack>
                  </AccordionButton>

                  <AccordionPanel as={Stack} p={0}>
                    <Box
                      hidden={
                        fact.remote_media_attachment?.length === 0 ?? true
                      }
                    >
                      <AspectRatio ratio={18 / 9}>
                        <Image
                          src={fact?.remote_media_attachment?.[0]?.link || ""}
                        />
                      </AspectRatio>
                    </Box>
                    <Stack hidden={(fact.questions ?? []).length <= 1}>
                      <Divider />
                      <Text px={4} fontSize="sm" fontWeight="bold">
                        Alternate Questions
                      </Text>
                      <UnorderedList px={8}>
                        {fact.questions
                          .filter((value, index) => index !== 0)
                          .map((question, index) => (
                            <ListItem key={index}>{question}</ListItem>
                          ))}
                      </UnorderedList>
                    </Stack>
                    <Stack>
                      <Divider />
                      <Text
                        px={4}
                        color="gray.600"
                        dangerouslySetInnerHTML={{
                          __html: fact.answer
                            .replace(/\n\n/g, "\n")
                            .replace(/\n/g, "<br/>"),
                        }}
                      />
                    </Stack>

                    <Stack
                      hidden={(fact.data?.followup_buttons ?? []).length === 0}
                    >
                      <Divider />
                      <Text px={4} fontWeight="bold">
                        Actions
                      </Text>
                      <ButtonGroup px={4}>
                        {fact.data &&
                          fact.data.followup_buttons &&
                          fact.data.followup_buttons.map((button) => (
                            <Button variant="secondary" size="xs">
                              {button}
                            </Button>
                          ))}
                      </ButtonGroup>
                    </Stack>
                    <Box h={4} />
                  </AccordionPanel>
                </AccordionItem>
              )
            )}
          </Accordion>
        </Stack>
        <KnowledgebaseChat flowId={flowId} />
      </SimpleGrid>
      <Portal>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader borderBottomWidth="thin" boxShadow="sm">
              <Heading size="sm">Import Facts</Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody as={Stack} py={4}>
              <Button
                onClick={() => {
                  window.open("/assets/sample_facts.csv");
                }}
                leftIcon={<ExportIcon />}
                variant="tertiary"
              >
                Download Sample CSV
              </Button>
              <CSVInput
                onParse={(csvRecords) => {
                  try {
                    if (csvRecords.length === 0) return setCsvData([]);
                    const parsedRecords = getFactsFromCSV(csvRecords);
                    if (csvRecords.length !== 0 && parsedRecords.length === 0)
                      return message({
                        title: "No Facts Detected",
                        description: "Please check the CSV file and try again",
                        status: "warning",
                      });
                    setCsvData(parsedRecords);
                  } catch (error) {
                    message({
                      description: error.message,
                      status: "warning",
                    });
                  }
                }}
              />
            </ModalBody>
            <ModalFooter hidden={csvData.length === 0} pt={0} pb={4}>
              <Button
                isLoading={loading}
                isDisabled={csvData.length === 0}
                onClick={() => importFacts(csvData)}
              >
                {csvData.length === 0
                  ? "Import facts"
                  : `Import ${csvData.length} facts`}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Portal>
    </>
  );
};

import React, { useState, useEffect } from "react";
import {
  Stack,
  Text,
  Input,
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  HStack,
} from "@chakra-ui/react";
import { CheckIcon, EditIcon } from "../../constants/icons"; // Ensure these icons exist

// Define the DataFormat type to align with the backend model
interface DataFormat {
  que: string;
  answer_field: string;
  answer: string;
  options: string[] | null;
  have_options: boolean;
  isEditing?: boolean; // Optional flag for toggling edit mode
}

interface AttributesListProps {
  data: DataFormat[]; // List of DataFormat objects
  isEdited?: boolean; // Boolean flag indicating if the data is being edited
  onSave: (updatedData: DataFormat[]) => void; // Callback to save updated data
}

const Attribute: React.FC<AttributesListProps> = ({ data, isEdited, onSave }) => {
  const [editingData, setEditingData] = useState<DataFormat[]>(data);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showEditIcon, setshowEditIcon] = useState<boolean>(true);
  const [showSaveButton, setshowSaveButton] = useState<boolean>(true);

  useEffect(() => {
    if (isEdited) {
      setshowEditIcon(false);
      setshowSaveButton(false);
    }
  }, [isEdited]);

  // Handle editing toggle for all fields
  const handleEdit = () => {
    setshowEditIcon(false); // Hide the edit icon when in edit mode
    setIsEditing(!isEditing); // Toggle all fields between edit mode
  };

  // Handle changes to input or select field
  const handleInputChange = (index: number, value: string) => {
    const updatedData = [...editingData];
    updatedData[index].answer = value;
    setEditingData(updatedData);
  };

  // Handle save of updated data
  const handleSave = () => {
    onSave(editingData); // Pass the updated data back to the parent
    setIsEditing(false); // Exit editing mode after saving
    setshowEditIcon(false); // Hide the edit icon after saving
    setshowSaveButton(false); // Hide the save button after saving
  };

  return (
    <Box position="relative" bg="gray.50" p={4}>
      <Text fontSize="2xl" fontWeight="bold" color="#2F3C7E" mb={4} mt={4} textAlign="center">
        Review Details
      </Text>
      {/* Edit Icon positioned at the top-right corner */}
      
      <Stack
        spacing={4}
        p={4}
        boxShadow="md"
        borderRadius="md"
        bg="rgb(244, 235, 249)"
        mx="auto" // Center the stack horizontally
        maxW={{ base: "90%", md: "80%", lg: "50%" }} // Responsive width
        position="relative" // Ensure the stack is positioned relatively for absolute positioning of the icon
      >
        {/* Edit Icon positioned at the top-right corner */}
        {showEditIcon && (
          <Tooltip label="Edit All Fields">
        <IconButton
          aria-label={"edit"}
          icon={<EditIcon />}
          size="lg"
          variant="tertiary"
          onClick={handleEdit}
          position="absolute"
          top={0}
          right={0}
          mb={4}
        />
          </Tooltip>
        )}
        {/* Add a gap between the edit icon and the first question-answer */}
        <Box height="10px" />
        {/* Render each data item as a key-value pair or form based on editing mode */}
        {editingData.map((item, index) => (
          <Box key={index} p={2}>
        {isEditing ? (
          <Stack direction="row" spacing={4} ml={8} alignItems="center">
            <Text fontWeight="bold" flex="1">
              {item.que.includes("*") ? (
              <span dangerouslySetInnerHTML={{ __html: item.que.replace(/\*(.*?)\*/g, "<b>$1</b>") }} />
              ) : (
              item.que
              )}
            </Text>
            {/* If the question has options, use a menu dropdown */}
            {item.have_options ? (
          <Menu>
            <MenuButton as={Button} flex="1" bg="white" borderColor="gray.300" _hover={{ borderColor: "gray.400" }}>
              {item.answer}
            </MenuButton>
            <MenuList>
              {item.options?.map((option, idx) => (
            <MenuItem key={idx} onClick={() => handleInputChange(index, option)}>
              {option}
            </MenuItem>
              ))}
            </MenuList>
          </Menu>
            ) : (
          // Otherwise, use a normal input field
          <Input
            value={item.answer}
            onChange={(e) => handleInputChange(index, e.target.value)}
            flex="1"
            bg="white"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
          />
            )}
          </Stack>
        ) : (
          <HStack spacing={4} ml={8} mr={10} alignItems="center" justifyContent="space-between">
            <Text flex="1" color="gray.800">
            {item.que.includes("*") ? (
              <span dangerouslySetInnerHTML={{ __html: item.que.replace(/\*(.*?)\*/g, "<b>$1</b>") }} />
              ) : (
              item.que
              )}
            </Text>
            <Text fontWeight="bold" flex="1" color="gray.700" textAlign="right">
          {item.answer}
            </Text>
          </HStack>
        )}
          </Box>
        ))}
      </Stack>
      {showSaveButton && (
        <Box textAlign="center" mt={4}>
          <Button colorScheme="purple" size="lg" onClick={handleSave}>
            Submit
          </Button>
        </Box>
      )}
      {!showSaveButton && (
        <Text color="green.500" fontWeight="bold" mt={6} textAlign={"center"}>
          Thank you, your data has been submitted successfully!
        </Text>
      )}
    </Box>
  );
};

export default Attribute;

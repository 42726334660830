import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"; // Adjust the path as needed
import { Stack, Spinner, Text } from "@chakra-ui/react";
import Attribute  from "./Attributes";
import config from "../../config";
import axios from 'axios';

interface ReviewFormProps {}

interface DataFormat {
  que: string;
  answer_field: string;
  answer: string;
  options: string[] | null;
  have_options: boolean;
}

const ReviewForm: React.FC<ReviewFormProps> = () => {
    const { id } = useParams<{ id: string }>(); // Extract the id from the URL
    const [attributes, setAttributes] = useState<DataFormat[] | null>(null);
    const [isEditied, setisEdited] = useState(false);
    const [loading, setLoading] = useState(true);

    // Fetch attributes based on the id
  useEffect(() => {
    const fetchAttributes = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${config.url}/api/v1/review/${id}`); // Replace with your actual API endpoint
        const data = response.data;
        setisEdited(data.isEdited);

        setAttributes(data.data);
      } catch (error) {
        console.error("Error fetching attributes:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchAttributes();
    }
  }, [id]);
  const handleSave = async(data) => {
    
    try {
      setisEdited(true);
      let json = { data: data, edited: true };
      const response = await axios.post(`${config.url}/api/v1/review/update/${id}`, json);
      
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  if (loading) {
    return (
      <Stack align="center" justify="center" height="100vh">
        <Spinner size="lg" />
        <Text>Loading attributes...</Text>
      </Stack>
    );
  }

  if (!attributes) {
    return <Text>No attributes found for the provided ID.</Text>;
  }

  return (
    <Stack>
      <Attribute
        data={attributes}
        isEdited={isEditied}
        onSave={(updatedAttributes) => handleSave(updatedAttributes)} // Update local state on save
      />
    </Stack>
  );
};

export default ReviewForm;
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Stack,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  useDisclosure,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  ButtonGroup,
  Text,
  useToast,
  Tooltip,
  Heading,
  SimpleGrid,
  CheckboxGroup,
  Checkbox,
  filter,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFlowFilters,
  getUsers,
  editUsers,
  UserRecord,
  IUsersQueryRequest,
  SortGroup,
} from "../../api/users";
import { Button } from "../../components/Button";
import { FilterField, FilterQuery } from "../../components/Filter/models";
import { InfiniteTable } from "../../components/InfiniteTable";
import {
  CloseIcon,
  DeleteIcon,
  SearchIcon,
  TagsIcon,
  AddIcon,
} from "../../constants/icons";
import { UserSummary } from "../../views/UserProfile";
import { TagGroup } from "../../components/TagGroup";
import {
  getFlowStatusBreakdown,
  getFlowSelectionStatusBreakdown,
  getFlowTags,
  IFlowFilterRequest,
  searchFacts,
} from "../../api/flow";
import { TagStack } from "../../components/TagStack";
import { DatePicker } from "../../components/Input";
import { FlowExport } from "../../components/FlowExport";
import { PageContext } from "../../router/layout";
import { useAuthToken } from "../../hooks/useAuthToken";
import { useMessageTemplates } from "../../data/flow/useMessageTemplates";
import { Exportmsg } from "../../components/Exportmsg";
import { Exportinvitations } from "../../components/Exportinvitations";
import { useFeatureFlags } from "../../data/flags/useFeatureFlags";
import { DISABLE_EXPORT_INVITATIONS } from "../../constants/feature_flags";
import { ENABLE_REMOVE_BUTTON } from "../../constants/feature_flags";

const PAGE_SIZE: number = 100;

const statusKey = "data.dashboard_status";
const selectionStatuskey = "data.selection_status"

export const Users = () => {
  const { id: flowId, campaignId: selectedCampaignId = "" } = useParams();
  const { getAuthToken } = useAuthToken();

  const {
    isOpen: isProfileOpen,
    onClose: onProfileClose,
    onOpen: onProfileOpen,
  } = useDisclosure();

  // prefetch templates from API
  const { data } = useMessageTemplates({
    flowId,
  });

  const message = useToast();
  const searchInputRef = useRef();
  const navigate = useNavigate();
  const isPageDocked = useContext(PageContext);

  const [filters, setFilters] = useState<FilterQuery[]>([]);
  const [users, setUsers] = useState<UserRecord[]>([]);
  const [lastDocumentIndex, setLastDocumentIndex] = useState(0);
  const [skip, setSkip] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const [flowFilters, setFlowFilters] = useState<FilterField[]>([]);
  const [sortGroup, setSortGroup] = useState<SortGroup[]>([
    { key: "last_message", order: "desc" },
  ]);

  const [loading, setLoading] = useState(false);
  const [hasMoreRecords, setHasMoreRecords] = useState(true);
  const [checkAllUsers, setCheckAllUsers] = useState<boolean>(false);

  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [currentUserProfile, setCurrentUserProfile] = useState<{
    userId: string;
    sessionId: string;
  }>();
  const [exportMsgBetween, setexportMsgBetween] = useState<any[]>([]);
  const [msgFileName, setmsgFileName] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [flowTags, setFlowTags] = useState<{ [x: string]: number }>({});
  const [flowStatus, setFlowStatus] = useState<{ [status: string]: number }>(
    {}
  );
  const [flowSelectionStatus, setFlowSelectionStatus] = useState<{ [status: string]: number }>(
    {}
  );
  const [filteredFlowTags, setFilteredFlowTags] = useState<{
    [status: string]: number;
  }>({});
  const [filteredFlowStatus, setFilteredFlowStatus] = useState<{
    [status: string]: number;
  }>({});
  const [filteredFlowSelectionStatus, setFilteredFlowSelectionStatus] = useState<{
    [status: string]: number;
  }>({});
  const [editNote, seteditNote] = useState(false)
  const [editStatus, seteditStatus] = useState(false)
  const [selectedRow, setselectedRow] = useState<string[]>([]);
  const [editableCell, setEditableCell] = useState({ row: -1, col: -1 });
  const [rowIndex, setrowIndex] = useState<number>(0);
  const { data: featureFlags } = useFeatureFlags(flowId);
  const isFeatureEnabled = (feature: string) => {
    return (featureFlags?.flags ?? []).includes(feature);
  };

  /**
   * fetches user data with filters, sort
   */
  const fetchUsers = (_skip: number) => {
    const fetchUsersData = async (
      token: string,
      abortController: AbortController
    ) => {

      if (flowId === undefined) return {};

      const request: IUsersQueryRequest = {
        flowId,
        token,
        abortController,
        filters,
        pagination: {
          skip: _skip,
          size: PAGE_SIZE,
        },
        sort: sortGroup,
      };

      setLoading(true);
      const response = await getUsers(request).catch((_) => {
        setLoading(false);
      });
      setLoading(false);

      if (response) {
        let uniqueUsers = new Map();

        if (_skip != 0) {
          users.map((user) =>
            uniqueUsers.set(user.data?.["Phone Number"] ?? "", user)
          );
        }
        //  else setUsers((state) => [...state, ...response.users]);

        response.users.forEach((user) => {
          let userConversationId = user.data?.["Phone Number"] ?? "";
          if (!uniqueUsers.has(userConversationId))
            uniqueUsers.set(userConversationId, user);
        });

        // setHasMoreRecords(PAGE_SIZE - Object.keys(uniqueUsers).length === 0);
        setUsers([...uniqueUsers.values()]);
        if (response.last_document_index) {
          setLastDocumentIndex(response.last_document_index);
          setHasMoreRecords(true);
        }
      }
    };

    const fetchFilteredFlowTags = async (
      token: string,
      abortController: AbortController
    ) => {
      if (flowId === undefined) return {};

      const request: IFlowFilterRequest = {
        flowId,
        token,
        abortController,
        filters,
      };

      setLoading(true);
      const response = await getFlowTags(request).catch((_) => {
        setLoading(false);
      });
      seteditNote(false)
      setLoading(false);

      if (response) {
        if (filters.length === 0) setFlowTags(response.tags);

        setFilteredFlowTags(response.tags);
      }
    };


    const fetchFilteredStatus = async (
      token: string,
      abortController: AbortController
    ) => {
      if (flowId === undefined) return {};

      const request: IFlowFilterRequest = {
        flowId,
        token,
        abortController,
        filters,
      };

      setLoading(true);
      const response = await getFlowStatusBreakdown(request).catch((_) => {
        setLoading(false);
      });
      setLoading(false);

      if (response) {
        let count = 0;
        Object.keys(response.status).map(
          (status) => (count += response.status[status])
        );
        setTotalUsers(count);
        setFilteredFlowStatus(response.status);

        if (filters.length === 0) setFlowStatus(response.status);
      }
    };
    const fetchFilteredSelectionStatus = async (
      token: string,
      abortController: AbortController
    ) => {
      if (flowId === undefined) return {};

      const request: IFlowFilterRequest = {
        flowId,
        token,
        abortController,
        filters,
      };


      setLoading(true);
      const response = await getFlowSelectionStatusBreakdown(request).catch((_) => {
        setLoading(false);
      });
      setLoading(false);

      if (response) {
        let count = 0;
        Object.keys(response.selectionStatus).map(
          (selectionStatus) => (count += response.selectionStatus[selectionStatus])
        );
        setFilteredFlowSelectionStatus(response.selectionStatus);

        if (filters.length === 0) setFlowSelectionStatus(response.selectionStatus);
      }
    };

    const fetchUserDataAbortController = new AbortController();
    const fetchFilteredFlowTagsAbortController = new AbortController();
    const fetchFilteredStatusAbortController = new AbortController();
    const fetchFilteredSelectionStatusAbortController = new AbortController();

    const fetchInfo = async () => {
      const token = localStorage.getItem('token')
      let toFetch = [fetchUsersData(token, fetchUserDataAbortController)];

      if (_skip == 0) {
        toFetch = [
          ...toFetch,
          fetchFilteredFlowTags(token, fetchFilteredFlowTagsAbortController),
          fetchFilteredStatus(token, fetchFilteredStatusAbortController),
          fetchFilteredSelectionStatus(token, fetchFilteredSelectionStatusAbortController),
        ];
      }

      Promise.all(toFetch);
    };

    fetchInfo();

    return () => {
      fetchUserDataAbortController.abort();
      fetchFilteredFlowTagsAbortController.abort();
      fetchFilteredStatusAbortController.abort();
      fetchFilteredSelectionStatusAbortController.abort();
    };
  };

  const getSourceOptions = () => {
    let keys = [""];

    flowFilters.map((value) => {
      if (value.key === "data.campaign_name") {
        keys = value.options ?? [];
      }
    });

    return keys;
  };

  // helper function to update array
  const updateFilters = (
    updatedFilters: FilterQuery[],
    filterKey: string = ""
  ) => {
    let filterKeys = [];
    let finalFilters: FilterQuery[] = [];

        if (updatedFilters.length === 0 && filterKey) filterKeys.push(filterKey);
    else updatedFilters.map((filter) => filterKeys.push(filter.key));

    if (filterKeys.length === 0) return;

    const hasKeyFilter = filters.filter((filter) =>
      filterKeys.includes(filter.key)
    );

    // No Filters with existing keys
    if (hasKeyFilter.length === 0)
      finalFilters = [...filters, ...updatedFilters];
    else {
      const removedFilters = filters.filter(
        (filter) => !filterKeys.includes(filter.key)
      );
      finalFilters = [...removedFilters, ...updatedFilters];
    }

    // remove existing
    setFilters(
      finalFilters.filter((filter) => {
        if (filter.value) {
          return filter.value.length !== 0;
        } else return false;
      })
    );
  };
  const updateSelectionFilters = (
    updatedFilters: FilterQuery[],
    filterKey: string = ""
  ) => {
    let filterKeys = [];
    let finalFilters: FilterQuery[] = [];

    if (updatedFilters.length === 0 && filterKey) filterKeys.push(filterKey);
    else updatedFilters.map((filter) => filterKeys.push(filter.key));

    if (filterKeys.length === 0) return;

    const hasKeyFilter = filters.filter((filter) =>
      filterKeys.includes(filter.key)
    );

    // No Filters with existing keys
    if (hasKeyFilter.length === 0)
      finalFilters = [...filters, ...updatedFilters];
    else {
      const removedFilters = filters.filter(
        (filter) => !filterKeys.includes(filter.key)
      );
      finalFilters = [...removedFilters, ...updatedFilters];
    }

    // remove existing
    setFilters(
      finalFilters.filter((filter) => {
        if (filter.value) {
          return filter.value.length !== 0;
        } else return false;
      })
    );
  };

  const loadMore = () => {
    fetchUsers(skip + lastDocumentIndex);
    setSkip(lastDocumentIndex);
  };

  /**
   * Stores the sorting config
   * @param columnKey
   * @param order
   */
  const sortData = (columnKey: string, order: "asc" | "desc") => {
    if (!order) return;
    setSortGroup([
      { key: columnKey === "Invited" ? "_id" : "last_message", order },
    ]);
  };

  const updateUserDetails = async (action: "hide" | "update", data = {}) => {
    setLoading(true);

    const token = localStorage.getItem('token')


    const response = await editUsers({
      flowId,
      action,
      token,
      filters: checkAllUsers
        ? filters.length === 0
          ? [{ key: "not_exists", operation: "ne", value: "" }]
          : filters
        : [{ key: "_id", operation: "in", value: data["dashboard_note"] || data["selection_status"] ? selectedRow : selectedRowIds}],
      data,
    }).catch((_) => {
      setLoading(false);
      message({
        status: "error",
        description: "Something went wrong",
      });
    });



    if (response) {
      setSelectedRowIds([]);
      fetchUsers(0);
      message({
        status: "success",
        description: `updated ${selectedRowIds.length} users`,
      });
    }

    setLoading(false);
    seteditStatus(false);
  };

  const clearFilter = (key: string) => {
    setexportMsgBetween([])
    return updateFilters(
      [
        {
          key,
          operation: "in",
          value: null,
        },
      ],
      key
    );
  };

  useEffect(() => {
    setSkip(0);
    setSelectedRowIds([]);
    fetchUsers(0);
  }, [sortGroup, filters]);

  // get total user count on first load
  useEffect(() => {
    const fetchFlowFilters = async (
      token: string,
      abortController: AbortController
    ) => {

      if (flowId === undefined) return {};

      setLoading(true);
      const response = await getFlowFilters({
        flowId,
        token,
        abortController,
      }).catch((_) => {
        setLoading(false);
      });
      setLoading(false);

      if (response) {
        setFlowFilters(response);
      }
    };

    const fetchFlowFiltersAbortController = new AbortController();

    const fetchInfo = async () => {
      const token = localStorage.getItem('token')
      await fetchFlowFilters(token, fetchFlowFiltersAbortController);
    };

    fetchInfo();

    return () => {
      fetchFlowFiltersAbortController.abort();
    };
  }, []);

  // triggers user api once input changes
  useEffect(() => {
    const getData = setTimeout(() => {
      let searchValue = `${searchQuery}`;
      const filterregex = /^[^a-zA-Z]*$/;

      let phone_searchValue = '';
      let name_searchValue = '';

      let regexresult = true;
      if(searchValue.length === 0){
        regexresult = true
      }else{
      regexresult = filterregex.test(searchValue)
      }
      if(regexresult){
        searchValue.trim()
        phone_searchValue = searchValue
      }else{
        name_searchValue = searchValue
      }

      const phone_searchKey = "conversation_id"
      const name_searchKey = "name"
      const searchKey = regexresult ? "conversation_id" : "name";


      let phone_searchFilter: FilterQuery = {
        key: phone_searchKey,
        operation: "regex",
        value: phone_searchValue,
      };
      let name_searchFilter: FilterQuery = {
        key: name_searchKey,
        operation: "regex",
        value: name_searchValue,
      };

      if(!regexresult) {
        let hasMultipleNames = searchValue.split(',');
        hasMultipleNames = hasMultipleNames.map(part => part.trim()).filter(part => part !== '');
        // if space or comma is detected
        if (hasMultipleNames.length > 1)
          name_searchFilter = {
            ...name_searchFilter,
            operation: "in",
            value: hasMultipleNames,
          };
      }







      const isPhoneNumber = /\d+/.test(searchValue);

      if (isPhoneNumber) {
        let hasMultipleNumbers = searchValue.split(/[\s,]+/);
        // if space or comma is detected
        if (hasMultipleNumbers.length > 1)
          phone_searchFilter = {
            ...phone_searchFilter,
            operation: "in",
            value: hasMultipleNumbers,
          };
      }

      updateFilters([phone_searchFilter, name_searchFilter], searchKey);
    }, 500);

    return () => clearTimeout(getData);
  }, [searchQuery]);
  const formatDatePart = (date) => {
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}_${day}`;
  };

  return (
    <Box>
      <SimpleGrid templateColumns="18rem auto">
        <Box
          p={4}
          borderRightWidth="thin"
          bg="whiteAlpha.800"
          w="18rem"
          h="100vh"
          position="sticky"
          top={0}
          bottom={0}
          pr={4}
        >
          <Heading py={2} size="md">
            {totalUsers} Users
          </Heading>

          <Stack my={6} spacing={4}>
            {/*  */}

            {/*  */}
            <Stack>
              <Heading size="sm">Invited</Heading>
              <ButtonGroup isAttached>
                <DatePicker
                  title="By Date"
                  date={{
                    from: undefined,
                    to: undefined,
                  }}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  variant="secondary"
                  mode={"range"}
                  onDateClear={() => clearFilter("timestamp")}
                  onDateSelected={(range) => {
                    let dateFilters = [range.from.getTime() / 1000];
                    const { from, to } = range;
                    let msgfilename = formatDatePart(from);
                    if (range.to) {
                      dateFilters.push(
                        range.to.getTime() / 1000 + 60 * 60 * 24
                      );
                      msgfilename += '_' + formatDatePart(to);
                      setexportMsgBetween(dateFilters);
                      setmsgFileName(msgfilename)
                    } else {
                      dateFilters.push(
                        range.from.getTime() / 1000 + 60 * 60 * 24
                      );
                      setexportMsgBetween(dateFilters);
                      setmsgFileName(msgfilename)
                    }
                    updateFilters(
                      [
                        {
                          key: "timestamp",
                          operation: "in",
                          value: dateFilters,
                        },
                      ],
                      "timestamp"
                    );
                  }}
                  py={1}
                />
                <TagGroup
                  multiple
                  placeholder="Select Value"
                  tags={[
                    ...(filters.filter(
                      (filter) => filter.key === "data.campaign_name"
                    )?.[0]?.value ?? []),
                  ]}
                  options={getSourceOptions()}
                  onChange={(selectedTags) =>
                    updateFilters(
                      [
                        {
                          key: "data.campaign_name",
                          operation: "in",
                          value:
                            typeof selectedTags === "string"
                              ? [selectedTags]
                              : selectedTags,
                        },
                      ],
                      "data.campaign_name"
                    )
                  }
                  allowNewTag={false}
                >
                  <Button borderLeftRadius={0} variant="secondary">
                    Source
                  </Button>
                </TagGroup>
              </ButtonGroup>
            </Stack>

            {/*  */}
            <Stack>
              <Heading size="sm">Last Interaction</Heading>
              <ButtonGroup isAttached>
                <DatePicker
                  title="By Date"
                  date={{
                    from: undefined,
                    to: undefined,
                  }}
                  variant="secondary"
                  mode={"range"}
                  onDateClear={() => clearFilter("last_message")}
                  onDateSelected={(range) => {
                    let dateFilters = [range.from.getTime() / 1000];
                    const { from, to } = range;
                    let msgfilename = formatDatePart(from);



                    if (range.to) {
                      dateFilters.push(
                        range.to.getTime() / 1000 + 60 * 60 * 24
                      );
                      msgfilename += '_' + formatDatePart(to);
                      setexportMsgBetween(dateFilters);
                      setmsgFileName(msgfilename)
                      // setmsgFileName();
                    } else {
                      dateFilters.push(
                        range.from.getTime() / 1000 + 60 * 60 * 24
                      );
                      setexportMsgBetween(dateFilters);
                      setmsgFileName(msgfilename)
                    }
                    updateFilters(
                      [
                        {
                          key: "last_message",
                          operation: "in",
                          value: dateFilters,
                        },
                      ],
                      "last_message"
                    );
                  }}
                  py={1}
                />
              </ButtonGroup>
            </Stack>

            {/*  */}
            <Stack>
              <Heading size="sm">Status</Heading>
              <CheckboxGroup
                onChange={(value) =>
                  updateFilters(
                    [{ key: statusKey, operation: "in", value }],
                    statusKey
                  )
                }
              >
                <Box maxH="40vh" overflowY="scroll">
                  {Object.keys(flowStatus).map((status, idx) => (
                    <Stack
                      py={1}
                      direction="row"
                      align="center"
                      justify="space-between"
                    >
                      <Checkbox key={idx} size="sm" value={status}>
                        <Text flex={1} textOverflow="ellipsis" fontSize="sm">
                          {status}
                        </Text>
                      </Checkbox>
                      <Text>
                        {filters.length === 0
                          ? flowStatus?.[status] ?? 0
                          : `${filteredFlowStatus?.[status] ?? 0} / ${
                              flowStatus?.[status] ?? 0
                            }`}
                      </Text>
                    </Stack>
                  ))}
                </Box>
              </CheckboxGroup>
            </Stack>
            <Stack>
              <Heading size="sm">Selection Status</Heading>
              <CheckboxGroup
                onChange={(value) =>
                  updateSelectionFilters(
                    [{ key: selectionStatuskey, operation: "in", value }],
                    selectionStatuskey
                  )
                }
              >
                <Box maxH="40vh" overflowY="scroll">
                  {Object.keys(flowSelectionStatus).map((selectionStatus, idx) => (
                    <Stack
                      py={1}
                      direction="row"
                      align="center"
                      justify="space-between"
                    >
                      <Checkbox key={idx} size="sm" value={selectionStatus}>
                        <Text flex={1} textOverflow="ellipsis" fontSize="sm">
                          {selectionStatus}
                        </Text>
                      </Checkbox>
                      <Text>
                        {filters.length === 0
                          ? flowSelectionStatus?.[selectionStatus] ?? 0
                          : `${filteredFlowSelectionStatus?.[selectionStatus] ?? 0} / ${
                              flowSelectionStatus?.[selectionStatus] ?? 0
                            }`}
                      </Text>
                    </Stack>
                  ))}
                </Box>
              </CheckboxGroup>
            </Stack>

            {/* <Insights
              flowId={flowId}
              onChange={(conversationIds) =>
                updateFilters(
                  [
                    {
                      key: "conversation_id",
                      operation: "in",
                      value: conversationIds,
                    },
                  ],
                  "conversation_id"
                )
              }
            /> */}
          </Stack>
        </Box>
        <Stack
          zIndex="base"
          as="main"
          m={0}
          p={0}
          maxW={`calc(100vw - 18rem - ${isPageDocked ? "5rem" : "15rem"})`}
        >
          <Stack h="full" justify="space-between">
            <Stack p={4} flex={1}>
              <Stack
                py={2}
                align="center"
                justify="space-between"
                direction="row"
              >
                <InputGroup size="sm" maxW="md">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon />}
                  />
                  <Input
                    ref={searchInputRef}
                    value={searchQuery}
                    onChange={(event) =>
                      setSearchQuery(event.currentTarget.value)
                    }
                    placeholder="Search for Name/Number"
                    bg="white !important"
                  />
                  <InputRightElement>
                    {searchQuery.length !== 0 && (
                      <CloseIcon onClick={() => setSearchQuery("")} />
                    )}
                  </InputRightElement>
                </InputGroup>

                <ButtonGroup>
                {flowId == 'radiomirchi_new' && (isFeatureEnabled(DISABLE_EXPORT_INVITATIONS) ? null : <Exportinvitations exportMsgBetween={exportMsgBetween} msgFileName={msgFileName} flowId={flowId}/>)}
                {flowId == 'radiomirchi_new' && (isFeatureEnabled(DISABLE_EXPORT_INVITATIONS) ? null : <Exportmsg exportMsgBetween={exportMsgBetween} msgFileName={msgFileName} flowId={flowId}/>)}
                  {/* <Button
                    variant="secondary"
                    isLoading={exportLoading}
                    leftIcon={<ExportIcon />}
                    onClick={() => exportCSV()}
                  >
                    Export
                  </Button> */}
                  {isFeatureEnabled(DISABLE_EXPORT_INVITATIONS) ? null : <FlowExport
                    flowId={flowId}
                    filters={
                      checkAllUsers
                        ? filters.length === 0
                          ? []
                          : filters
                        : selectedRowIds.length === 0
                        ? []
                        : [
                            {
                              key: "_id",
                              operation: "in",
                              value: selectedRowIds,
                            },
                          ]
                    }
                    count={totalUsers}
                  />}
                  <Button
                    leftIcon={<AddIcon />}
                    onClick={() => navigate(`/dashboard/${flowId}/users/new`)}
                  >
                    New User
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>
            <Box px={4} position="relative" width="full" h={12}>
              <TagStack
                filterKey="data.tags"
                tags={filteredFlowTags}
                onTagChange={(filters) => updateFilters(filters, "data.tags")}
              />
            </Box>

            <Box maxH="calc(100vh - 8rem)" overflowY="hidden">
              <InfiniteTable
                flowId={flowId}
                records={users}
                hasMore={hasMoreRecords}
                loading={loading}
                editNote={editNote}
                setrowIndex={setrowIndex}
                editStatus={editStatus}
                editableCell={editableCell}
                fetchUser={(_skip:number) => {
                  fetchUsers(_skip)
                }}
                handleupdateUserDetails={(action: "hide" | "update", data?: any) => {
                  updateUserDetails(action, data)
                }}
                loadMoreRecords={() => {
                  !loading && loadMore();
                }}
                onSort={sortData}
                onCellClick={(user, columnKey, index) => {
                  setCurrentUserProfile({
                    userId: user.data?.["Phone Number"] ?? "",
                    sessionId: user.id,
                  });
                  const columnIndex = Object.keys(user.data).indexOf(columnKey);

                  setEditableCell({ row: index, col: columnIndex });
                  if(columnKey === 'Comment'){

                    let id: string[] = [];
                    id.push(user.id);
                    setselectedRow(id)
                    seteditNote(true)
                  }else if(columnKey === 'Selection Status'){
                    let id: string[] = [];
                    id.push(user.id);
                    setselectedRow(id)
                    seteditStatus(true)
                  }else {

                    onProfileOpen();
                };
                }}

                onNoteClose={() => {
                  seteditNote(false)
                  fetchUsers(0)
                  }
                }
                onStatusClose={() => {
                  seteditStatus(false)
                  fetchUsers(0)
                  }
                }
                onEditNote={() => seteditNote(true)
                }
                onRowsSelected={(selectedRows) =>{
                  setSelectedRowIds(selectedRows);
                 }
                }
                onSelectAll={(select) => setCheckAllUsers(select)}
                onFilter={(filters) => updateFilters(filters)}
                bg="white"
                actions={
                  <Stack
                    direction="row-reverse"
                    align="center"
                    justify="space-between"
                    p={4}
                    hidden={selectedRowIds.length === 0}
                  >
                    <Tooltip
                      label={
                        selectedRowIds.length === 0 && "select any user(s)"
                      }
                    >
                      <ButtonGroup isDisabled={selectedRowIds.length === 0}>
                      {/* { !editNote && (Object.keys(dashboardNote).length > 0 ? (
                            <Button leftIcon={<TagsIcon />} onClick={ () => seteditNote(true)} isDisabled={selectedRowIds.length > 1}>Edit note</Button>
                          ) : (
                            <Button leftIcon={<TagsIcon />} onClick={ () => seteditNote(true)} isDisabled={selectedRowIds.length > 1}>Add note</Button>
                          ))}
                      { editNote && selectedRowIds.length === 1 && <DashboardNote
                          note={dashboardNote}
                          allowEditing
                          onChange={(dashboard_note) => {
                            setdashboardNote(dashboard_note);
                            updateUserDetails("update", { dashboard_note });
                          }}
                          onClose={() => {seteditNote(false); }}
                        >

                        </DashboardNote>} */}
                        <TagGroup
                          multiple
                          placeholder="Select value"
                          tags={[""]}
                          options={Object.keys(flowTags)}
                          onChange={(tags) =>
                            updateUserDetails("update", { tags })
                          }
                        >
                          <Button leftIcon={<TagsIcon />}>Assign Tags</Button>
                        </TagGroup>

                        {isFeatureEnabled(ENABLE_REMOVE_BUTTON) && <Button
                          askConfirmation
                          confirmText={`Are you sure you wanna remove ${selectedRowIds.length} user(s)?`}
                          leftIcon={<DeleteIcon />}
                          onClick={() => updateUserDetails("hide")}
                        >
                          Remove
                        </Button>}
                      </ButtonGroup>
                    </Tooltip>
                    <Text fontSize="md" fontWeight="bold">
                      {checkAllUsers ? totalUsers : selectedRowIds.length} Users
                      selected
                    </Text>
                  </Stack>
                }
              />
            </Box>
          </Stack>
        </Stack>
      </SimpleGrid>

      <Drawer
        blockScrollOnMount={false}
        size="xl"
        isOpen={isProfileOpen}
        onClose={onProfileClose}
      >
        <DrawerOverlay bg="blackAlpha.800" backdropFilter="auto" backdropBlur="6px" />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="thin">Summary</DrawerHeader>
          <DrawerBody p={0} m={0}>
            <Box
              p={0}
              m={0}
              pos="relative"
              position="relative"
              h={`calc(100vh - 4rem)`}
            >
              {currentUserProfile && (
                <UserSummary
                  flowId={flowId}
                  sessionId={currentUserProfile.sessionId}
                  userId={currentUserProfile.userId}
                  onClose={onProfileClose}
                />
              )}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
